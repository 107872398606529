import { Routes, Route, Outlet, Link } from 'react-router-dom';
import { useCounterStore, useThemeStore } from './store'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Counter from './components/Counter';
import LoginPage from './components/LoginPage';
import HomePage from './pages/home/HomePage';
import { Box } from '@mui/material';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from './authConfig';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  
  if(accounts.length == 0){
    instance.loginRedirect(loginRequest).then(response => {
      console.log(response)
     }).catch(e => {
      console.log(e);
    });
  }
  // if(!isAuthenticated){
  //   instance.loginRedirect(loginRequest).catch(e => {
  //     console.log(e);
  //   });
  // }
  console.log('isAuthenticated in APP : ', isAuthenticated, accounts)
  const increment = useCounterStore((state) => state.increment)
  const decrement = useCounterStore((state) => state.decrement)
  // return (
  //     <div>
  //     <Routes>
  //       <Route path="/" element={<Layout />}>
  //         <Route index element={<LoginPage />} />
  //         <Route path="*" element={<NoMatch />} />
  //       </Route>
  //     </Routes>
  //   </div>
  // );
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
};

function Layout() {
  return (
    <>
    
      <Navbar />
      <Box sx={{minHeight: 'calc(100vh - 60px - 8px)', display:'flex', alignItems:'stretch'}}>
        <Outlet />
      </Box>
      {/* <Footer /> */}
    </>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
      <Counter />
    </div>
  );
}

function Dashboard() {
  const styles = {
    container: {
      margin: 'auto',
      width: '50%',
      padding: '10px',
      textAlign: 'center'
    },
  } as const;
  return (
    <div style={styles.container}>
      <h2 style={styles.container}>Login</h2>
    </div>
  );
}


function Login() {
  return (
    <div>
      <h2>Redirecting...</h2>
      {/* <p>
        <Link to="/">Go to the home page</Link>
      </p> */}
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
