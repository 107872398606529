import React from 'react';
import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
// import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';
import Image from '../components/Image'
// import IconAvatar from ''
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export default function Navbar() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  console.log('isAuthenticated : ', isAuthenticated, accounts)
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const [anchorElUser, setAnchorElUser] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(true);
  };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(false);
  };

    return (
      <>
      <AppBar position='static'  elevation={0} component="nav" sx={{background: '#FFBD52',boxShadow: '0px 2px 10px 4px rgba(0, 107, 107, 0.15)',}}>
      {/* <Container maxWidth="xl"> */}
        <Toolbar sx={{justifyContent: 'space-between', alignItems: 'center', background: '#FFBD52', color: 'white', height: '60px', p:'0 16px 0 1px!important'}}>
          
          <Box sx={{ display:'flex',flexFlow:'row nowrap', flexGrow: 1, alignItems:'center' }}>
            <Box display='block' component='i' sx={{mr: 2}}><Image src={Logo} height='60' style={{display: 'block'}} /></Box>
            <Typography component='h1' sx={{fontWeight: '600', fontSize: '24px', color:'#4F4F4F'}}>Supplier list</Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{display:'flex', flexFlow:'row nowrap', alignItems:'center'}}>
              {isAuthenticated ? <Typography sx={{fontSize: '14px', mr:2, color:'#4F4F4F'}}>{accounts[0].username}</Typography> : null }
              {/* <Typography sx={{fontSize: '14px', mr:2, color:'#4F4F4F'}}>Username</Typography> */}
            {/* <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt="Username" src='https://mui.com/static/images/avatar/2.jpg' />
                </IconButton>
            </Tooltip> */}
            </Box>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              // anchorEl={anchorElUser}
              // anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={anchorElUser}
              onClose={handleCloseUserMenu}
              onClick={handleOpenUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}

                {/* <MenuItem key={1} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center"><Link to="/">Home</Link></Typography>
                </MenuItem> */}

            </Menu>
          </Box>
          {/* <Box component='ul' sx={{ display: 'flex', listStyleType: 'none' }}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/nothing-here">Nothing Here</Link>
            </li>
          </Box> */}
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      </>
    );
  }