export const typeOfConsultant = [
    {
        key:'a0',
        text:'PM',
        value:'PM'
    },
    {
        key:'a1',
        text:'PMC',
        value:'PMC'
    },
    {
        key:'a2',
        text:'CM',
        value:'CM'
    },
    {
        key:'a3',
        text:'QS',
        value:'QS'
    },
    {
        key:'a4',
        text:'EIA',
        value:'EIA'
    },
    {
        key:'a5',
        text:'Façade Consultant',
        value:'Façade Consultant'
    },
    {
        key:'a6',
        text:'Traffic Consultant',
        value:'Traffic Consultant'
    },
    {
        key:'a7',
        text:'Sustainability Consultant',
        value:'Sustainability Consultant'
    },
    {
        key:'a8',
        text:'F&B Consultant',
        value:'F&B Consultant'
    },
    {
        key:'a9',
        text:'IT Consultant',
        value:'IT Consultant'
    },
    {
        key:'a10',
        text:'AV Consultant',
        value:'AV Consultant'
    },
    {
        key:'a11',
        text:'Fire Life Safety Consultant',
        value:'Fire Life Safety Consultant'
    },
    {
        key:'a12',
        text:'FF&E Consultant',
        value:'FF&E Consultant'
    },
    {
        key:'a13',
        text:'OS&E Consultant',
        value:'OS&E Consultant'
    },
    {
        key:'a14',
        text:'Flooding Consultant',
        value:'Flooding Consultant'
    },
    {
        key:'a15',
        text:'Commissioning for Sustainability',
        value:'Commissioning for Sustainability'
    },
    {
        key:'a16',
        text:'Kitchen Specialist Consultant',
        value:'Kitchen Specialist Consultant'
    },
    {
        key:'a17',
        text:'LEED Consultant',
        value:'LEED Consultant'
    },
    {
        key:'a18',
        text:'Security Consultant',
        value:'Security Consultant'
    },
    {
        key:'a19',
        text:'Vertical Consultant',
        value:'Vertical Consultant'
    },
    {
        key:'a20',
        text:'Art Consultant',
        value:'Art Consultant'
    },
    
]

export const typeOfDesigner = [
    {
        key:'b0',
        text:'Master Plan Designer',
        value:'Master Plan Designer'
    },
    {
        key:'b1',
        text:'Local Architectural Designer',
        value:'Local Architectural Designer'
    },
    {
        key:'b2',
        text:'Structural Engineering Designer',
        value:'Structural Engineering Designer'
    },
    {
        key:'b3',
        text:'MEP Engineering Designer',
        value:'MEP Engineering Designer'
    },
    {
        key:'b4',
        text:'Interior Designer (Concept)',
        value:'Interior Designer (Concept)'
    },
    {
        key:'b5',
        text:'Interior Designer (Local)',
        value:'Interior Designer (Local)'
    },
    {
        key:'b6',
        text:'Landscape Designer',
        value:'Landscape Designer'
    },
    {
        key:'b7',
        text:'Lighting Designer',
        value:'Lighting Designer'
    },
    {
        key:'b8',
        text:'Signage Designer',
        value:'Signage Designer'
    },
]

export const typeOfContractorSubContractor = [
    {
        key:'c0',
        text:'Structure and Architect Contractor',
        value:'Structure and Architect Contractor'
    },
    {
        key:'c1',
        text:'MEP Contractor',
        value:'MEP Contractor'
    },
    {
        key:'c2',
        text:'Soft-Scape Contractor',
        value:'Soft-Scape Contractor'
    },
    {
        key:'c3',
        text:'Hardscape Contractor',
        value:'Hardscape Contractor'
    },
    {
        key:'c4',
        text:'Façade Contractor',
        value:'Façade Contractor'
    },
    {
        key:'c5',
        text:'Aluminium Window and Door Contractor',
        value:'Aluminium Window and Door Contractor'
    },
    {
        key:'c6',
        text:'Interior Contractor',
        value:'Interior Contractor'
    },
    {
        key:'c7',
        text:'Signage and Digital Signage Contractor',
        value:'Signage and Digital Signage Contractor'
    },
    {
        key:'c8',
        text:'AV Contractor',
        value:'AV Contractor'
    },
    {
        key:'c9',
        text:'IT Contractor',
        value:'IT Contractor'
    },
    {
        key:'c10',
        text:'Infrastructure Contractor',
        value:'Infrastructure Contractor'
    },
    {
        key:'c11',
        text:'Land Filled and Earth work',
        value:'Land Filled and Earth work'
    },
    {
        key:'c12',
        text:'Pilling Work and D-wall',
        value:'Pilling Work and D-wall'
    },
    {
        key:'c13',
        text:'Main Contractor (S&A/MEP) for Sales Gallery',
        value:'Main Contractor (S&A/MEP) for Sales Gallery'
    },
    {
        key:'c14',
        text:'Interior Contractor for Sales Gallery',
        value:'Interior Contractor for Sales Gallery'
    },
    {
        key:'c15',
        text:'Automatic Car Parking',
        value:'Automatic Car Parking'
    },
    {
        key:'c16',
        text:'Home Automation',
        value:'Home Automation'
    },
]

export const typeOfSupplierDealeFactory = [
    {
        key:'d0',
        text:'Ceramic Floor',
        value:'Ceramic Floor'
    },
    {
        key:'d1',
        text:'Marble',
        value:'Marble'
    },
    {
        key:'d2',
        text:'Sanitary ware & Fitting',
        value:'Sanitary ware & Fitting'
    },
    {
        key:'d3',
        text:'Engineering Floor',
        value:'Engineering Floor'
    },
    {
        key:'d4',
        text:'Laminate Floor',
        value:'Laminate Floor'
    },
    {
        key:'d5',
        text:'CCTV and Security',
        value:'CCTV and Security'
    },
    {
        key:'d6',
        text:'Lighting Fixture',
        value:'Lighting Fixture'
    },
    {
        key:'d7',
        text:'Lift and Escalator',
        value:'Lift and Escalator'
    },
    {
        key:'d8',
        text:'Kitchen Furniture',
        value:'Kitchen Furniture'
    },
    {
        key:'d9',
        text:'Wardrobe',
        value:'Wardrobe'
    },
    {
        key:'d10',
        text:'Vanity',
        value:'Vanity'
    },
    {
        key:'d11',
        text:'Loose Furniture',
        value:'Loose Furniture'
    },
    {
        key:'d12',
        text:'Curtain',
        value:'Curtain'
    },
    {
        key:'d13',
        text:'Carpet and Area Rug',
        value:'Carpet and Area Rug'
    },
    {
        key:'d14',
        text:'Sauna and Steam',
        value:'Sauna and Steam'
    },
    {
        key:'d15',
        text:'Decorative Lighting',
        value:'Decorative Lighting'
    },
    {
        key:'d16',
        text:'Facade Lighting',
        value:'Facade Lighting'
    },
]

export const typeOfOther = [
    {
        key:'e0',
        text:'Agent',
        value:'Agent'
    },
    {
        key:'e1',
        text:'Shipping',
        value:'Shipping'
    },
    {
        key:'e2',
        text:'Forwarder',
        value:'Forwarder'
    },
    {
        key:'e3',
        text:'Packing',
        value:'Packing'
    },
    {
        key:'e4',
        text:'Advertising',
        value:'Advertising'
    },
    {
        key:'e5',
        text:'Printing',
        value:'Printing'
    },
    {
        key:'e6',
        text:'Event',
        value:'Event'
    },
    {
        key:'e7',
        text:'Branding',
        value:'Branding'
    },
    {
        key:'e8',
        text:'Housekeeping and Cleaning / บริษัทแม่บ้านและทำความสะอาด',
        value:'Housekeeping and Cleaning / บริษัทแม่บ้านและทำความสะอาด'
    },
    {
        key:'e9',
        text:'Security Guard / บริษัทรักษาความปลอดภัย',
        value:'Security Guard / บริษัทรักษาความปลอดภัย'
    },
    {
        key:'e10',
        text:'Gardening & Landscaping / บริษัทดูแลสวน',
        value:'Gardening & Landscaping / บริษัทดูแลสวน'
    },
    {
        key:'e11',
        text:'Other',
        value:'Other'
    },
]

export const typeOfVender = [
    {
        key: 'vender1',
        text: 'Consultant',
        value: 'Consultant'
    },
    {
        key: 'vender2',
        text: 'Designer',
        value: 'Designer'
    },
    {
        key: 'vender3',
        text: 'Contractor / Sub-Contractor',
        value: 'Contractor / Sub-Contractor'
    },
    {
        key: 'vender4',
        text: 'Supplier / Dealer',
        value: 'Supplier / Dealer'
    },
    {
        key: 'vender5',
        text: 'Other',
        value: 'Other'
    },
]