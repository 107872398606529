import React from 'react';
import './index.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { store,persistor } from './store/store';
import theme from './styles/theme';
import App from './App';

import { msalConfig } from './authConfig';
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ThemeProvider>
  </React.StrictMode>
);
