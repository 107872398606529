import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ItemsState {
  firstName: string;
  lastName: string;
  lat: string;
  lng: string;
  telephone: string;
  email: string;
}

const initialState: ItemsState = {
  firstName: '',
  lastName: '',
  lat: '',
  lng: '',
  telephone: '',
  email: '',
};

export const AddItemsSlice = createSlice({
  name: 'addItems',
  initialState,
  reducers: {
    clearDataFilter: (state) => initialState,
    updateAddItemsState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateAddItemsState, clearDataFilter } = AddItemsSlice.actions;

export default AddItemsSlice.reducer;
